import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useAuth } from '../../hooks/useAuth'
import toast from 'react-hot-toast'
import OtpInput from 'react-otp-input'
import es from 'react-phone-number-input/locale/es.json';

export default function PhoneAuth({ recaptcha }) {
  const [digits, setDigits] = useState('');
  const [valid, setValid] = useState(false);
  const [validating, setValidating] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [code, setCode] = useState('');
  const router = useRouter();
  const { logInWithPhone } = useAuth();

  useEffect(() => {
    setValid(isValidPhoneNumber(digits ?? ''))
  }, [digits]);

  const signInWithPhoneNumber = async () => {
    try{
      const rs = await logInWithPhone(digits, recaptcha);
      setConfirmationResult(rs);
    }catch (e) {
      console.log(e)
    }

  };

  const verifyCode = async () => {
    if(code && code.length === 6){
      try{
        setValidating(true);
        const result = await confirmationResult.confirm(code);
        router.push('/')
      }catch (e) {
        // Sentry.captureException(e);
        setValidating(false);
        if(e?.code === 'auth/invalid-verification-code'){
          toast.error('El codigo que ingresastes es invalido.')
        }else{
          toast.error('Ocurrio un error.')
        }
        console.log(e)
      }

    }
  };

  return(
    <div>
      { confirmationResult === null &&
        <p className="dark:text-jacarta-300 mb-10 text-lg leading-normal">
          Ingrese su numero de celular.
        </p>
      }
      { confirmationResult !== null &&
        <p className="dark:text-jacarta-300 mb-10 text-lg leading-normal">
          Ingrese código de verificación.
        </p>
      }
      {confirmationResult === null && (<div className="flex flex-col">
        <PhoneInput
          labels={es}
          placeholder="Número de teléfono"
          defaultCountry="PA"
          value={digits}
          onChange={setDigits}/>
          <button onClick={() => {
            signInWithPhoneNumber()
          }} className="dark:bg-jacarta-700 mt-4 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
            <span>Iniciar</span>
          </button>
        </div>
      )}
      {confirmationResult !== null && (
        <div  className="flex w-full items-center flex-col justify-center">
          <OtpInput
            isInputNum
            containerStyle="otpContainer"
            inputStyle="inputStyle"
            value={code}
            onChange={setCode}
            numInputs={6}
            separator={<span>-</span>}
          />
          <button disabled={!(code && code.length === 6)} onClick={verifyCode} className="dark:bg-jacarta-700 mt-4 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center rounded-full border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent">
            <span>Validar</span>
          </button>
        </div>
      )}

    </div>
  )



}