import React, { useEffect, useRef, useState } from 'react'
import Link from "next/link";
import "react-tabs/style/react-tabs.css";
import Meta from "../../components/Meta";
import { useRouter } from 'next/router'
import { auth } from '../../lib/firebase'
import { RecaptchaVerifier } from 'firebase/auth'
import PhoneAuth from '../../components/auth/PhoneAuth'

const Login = () => {
  const router = useRouter();
  const [recaptcha, setRecaptcha] = useState(null);
  const element = useRef(null);

  useEffect(() => {
    if (!recaptcha) {
      const verifier = new RecaptchaVerifier(element.current, {
        size: 'invisible',
      }, auth);
      setRecaptcha(verifier)
    }
  });


  return (
    <div>
      <Meta title="Login || Burger Fest 2024" />
      {/* <!-- Login --> */}
      {recaptcha &&
        <section className="relative h-screen">
          <div className="lg:flex lg:h-full">
            {/* <!-- Left --> */}
            <div className="relative text-center lg:w-1/2">
              <img
                src="/images/login.jpg"
                alt="login"
                className="absolute h-full w-full object-cover"
              />
              {/* <!-- Logo --> */}
              <Link href="/">
                <a className="relative inline-block py-36">
                  <img
                    src="/images/logo_w_2024.webp"
                    className="inline-block max-h-28"
                    alt="Burger Fest 2023"
                  />
                </a>
              </Link>
            </div>

            {/* <!-- Right --> */}
            <div className="relative flex items-center justify-center p-[10%] lg:w-1/2">
              <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                <img
                  src="/images/gradient_light.jpg"
                  alt="gradient"
                  className="h-full w-full"
                />
              </picture>

              <div className="w-full max-w-[25.625rem] text-center">
                <h1 className="text-jacarta-700 font-display mb-6 text-4xl dark:text-white">
                  Iniciar sesión
                </h1>
                <PhoneAuth recaptcha={recaptcha} />
              </div>
            </div>
          </div>
        </section>}
      {/* <!-- end login --> */}
      <div ref={element}></div>
    </div>
  );
};

export default Login;
